<template>
  <DefaultButton
    small
    :bgColor="bgcolor"
    :color="color"
    :class="[
      zgNoteButon ? 'zg-single-note-button' : 'zg-info-button',
      noYMargin ? 'my-0' : '',
      noXMargin ? 'mx-0' : '',
    ]"
    icon
    :loading="loading"
    :disabled="disabled"
    @click.stop="$emit('clicked')"
  >
    <v-tooltip top :disabled="!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-layout shrink v-on="on" v-bind="attrs" align-center>
          <slot></slot>
          <v-icon
            small
            :style="{ verticalAlign: 'middle', fontSize: `${fontSize} !important` }"
            >{{ icon }}</v-icon
          >
        </v-layout>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </DefaultButton>
</template>
<script>
export default {
  props: {
    fontSize: {
      default: "22px",
    },
    icon: {
      default: "mdi-close",
    },
    color: {
      default: "icons",
    },
    bgcolor: {
      default: "white",
    },
    noYMargin: {
      type: Boolean,
      default: false,
    },
    noXMargin: {
      type: Boolean,
      default: false,
    },
    zgNoteButon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: ""
    }
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
};
</script>

<style lang="scss" scoped>
.zg-info-button {
  border-radius: 15px !important;
  height: 22px !important;
  width: 22px !important;
}
.zg-single-note-button {
  border-radius: 15px !important;
  height: 32px !important;
  width: 32px !important;
}
.zg-single-note-button::v-deep .v-btn__loading {
  color: white !important;
}
.zg-info-button::v-deep .v-btn__loading {
  color: white !important;
}
</style>
